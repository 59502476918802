import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import Contact from '../components/Contact'
import Seo from "../components/seo"

const Uygulamalar = () => {
    return (
        <Layout>
            <Seo title="Uygulamalar" />
            <div class="uygulamalar">
                <StaticImage
                    src="../images/uygulamalar.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="uygulamalar"
                    className="üstimg"
                    placeholder={'blurred'}
                />

                <div class="uygulamalar-cerceve">
                    <h2 class="uygulamalar-yazi">SU ARITMA ÇÖZÜMLERİMİZ</h2>
                    <div class="uygulamlar-temizsu">

                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/filtrasyon.JPEG"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="filtrasyon"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Filtrasyon Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Filtrasyon sistemleri suyunuzun istenilen mikron hassasiyetinde arıtımını sağlayarak,
                                    askıdaki katı maddelerin gideriminde en etkili yöntemdir. Bununla birlikte medya filtrasyon
                                    sistemleri suyunuzda bulunan klor, renk, tat, koku veren eriyik gazların, organik
                                    maddelerin, bazı minarellerin ve ağır metallerin
                                    giderilmesi için de
                            kullanılır.<br />
                                    <ul className="ulmargin">
                                        <li>Multimedya Filtrasyon Sistemleri</li>
                                        <li>Aktif Karbon Filtrasyon Sistemleri</li>
                                        <li>Demir - Mangan Filtrasyon Sistemleri</li>
                                        <li>Arsenik Filtrasyon Sistemleri</li>
                                    </ul>
                                </h4>
                            </div>
                        </div>
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/yumusatma1.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="yumusatma"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Yumuşatma Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Su yumuşatma sistemlerinin temel çalışma prensibi iyon değiştirme yöntemi ile suda sertliğe
                                    neden olan kalsiyum (Ca+²) ve magnezyum (Mg+²) iyonlarının sudan uzaklaştırılmasına yardımcı
                                    olmasıdır. Katyonik reçinede bulunan sodyum (Na+¹) iyonlarının, kalsiyum ve magnezyum
                                    iyonları ile yer değiştirerek gerçekleştirdiği uzaklaştırma işlemidir.
                        </h4>
                            </div>
                        </div>
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/deiyonizasyon.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="deiyonizasyon"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Deiyonizasyon Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Deiyonize sular içeriğinde sadece H2O bulunduran tüm anyon ve katyonlardan uzaklaştırılmış
                                    pH derecesi 7 (nötr) olan sulardır. Deiyonize sularda iletkenlik 0 mikrosiemens daha hassas
                                    ölçümlerde
                                    18 ohm değerlere sahiptir. Bu değerlerdeki sular saf ve bu değerlere en yakın sular safa
                                    yakın su olarak değerlendirlilir.
                        </h4>
                            </div>
                        </div>
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/dozajlama.png"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="dozajlama"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Dozajlama Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Suda mevcut nitrit, demir, mangan gibi oksitlenebilen maddeleri oksitlemek ve bakteri, virüs
                                    gibi kirlilikleri ise dezenfekte etmek amacıyla çeşitli kimyasallar kullanılmaktadır.
                                    Dozajlama sistemlerimiz standart debi kontrolü ve redox kontrollü olarak dizayn edilmiştir.
                                    Dezenfekte edilecek kapasiteye göre pompa seçimi tarafımızdan yapılmaktadır. Dozaj
                                    Sistemleri Standart Tip, ORP Kontrollü ve
                                    Debi kontrollü modellerden oluşmaktadır
                        </h4>
                            </div>
                        </div>
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/tersozmoz.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="tersozmoz"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Ters Ozmoz Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Klasik arıtım sistemlerinin yetersiz kaldığı sulara (denizsuyu, iletkenliği yüksek olan
                                    sular , kuyu suları vb.) uygulanan, suyun içindeki istenmeyen tüm mineralleri sudan ayıran,
                                    saf su eldesine yönelik membran filtrasyon işlemine Ters Ozmoz denilmektedir. Kimyasal
                                    tuzlara sahip olan yüksek konsantrasyondaki suyun %90-99 oranında arıtılarak saf
                                    suya yakın düşük konsantrasyonda su elde edilmesi için kullanılan sistemlerdir.
                        </h4>
                            </div>
                        </div>
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/Ultrafiltrasyon.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="filtrasyon"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Ultrafiltrasyon Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Ultrafiltrasyon sistemleri suyun kimyasal özelliklerine müdahalede bulunmadan suyun
                                    fiziksel özelliklerinde ciddi fark yaratır. Ultrafiltrasyon sistemleri ile suda bulunan
                                    askıda katı madde ve bulanıklık giderimi yüksek düzeyde sağlanırken belirli oranda renk
                                    giderimi de sağlanmaktadır. Ultrafiltrasyon sistemlerinin en önemli özelliklerinden birisi
                                    de suda bulunması muhtemel bakteri ve virüslerin tamamını giderebilmesidir.
                        </h4>
                            </div>
                        </div>
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/Ultraviyole.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="Ultraviyole"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Ultraviyole Sistemler
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Ultraviyole sistemleri ile dezenfeksiyon, suya herhangi bir kimyasal veya oksidant ilave
                                    edilmeksizin mikroorganizmaların etkisiz hale getirilmesi işlemidir. Tüp benzeri cam bir
                                    kılıfın içine yerleştirilmiş ultraviyole lambası sayesinde cihaza giren suya dozajı ayarlı
                                    UV IŞINI verilir. Bu ultraviyole ışınları mikroorganizmaların DNA yapısını bozarak etkisiz
                                    hale gelmelerini sağlar. Bu şekilde ortalama % 99.9 oranında dezenfeksiyon verimi elde
                                    edilmektedir.
                        </h4>
                            </div>
                        </div>
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/evsel.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="evsel"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Evsel Su Arıtma Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Şebeke suyu kalitesinin yetersiz kaldığı günümüz koşullarında evsel su arıtma
                                    sistemleri, şişe suyu kalitesinde su arıtımı yapmaktadır. Doğal kaynak suyu kalitesinde çay,
                                    kahve, yemek, içme suyu olarak kullanabileceğiniz sağlıklı ve temiz su sağlar. Genleşme
                                    tankı ve şık musluğu ile şişelenmiş su sipariş etmenize ve beklemenize gerek kalmaz. Düşük
                                    işletme maliyeti ile ekonomik sistemlerdir.
                        </h4>
                            </div>
                        </div>
                    </div>



                    <h2 class="uygulamalar-yazi">ATIK SU ARITMA ÇÖZÜMLERİMİZ</h2>
                    <div class="uygulamlar-temizsu">
                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/biyolojik.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="biyolojik"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Biyolojik Arıtma Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Biyolojik atık su arıtma ünitemiz evsel atıksuların arıtılmasında teknolojinin
                                    gelişmesiyle birlikte avantajlı hale gelmiş olan ve havalandırma ile çökeltimin aynı reaktör
                                    içinde gerçekleştirildiği ardışık kesikli reaktör prosesli olup evsel nitelikli atıksuların
                            arıtılması amacı ile geliştirilmiştir.<br />
                                            Arıtılan atık suyun geri kazanımı istenilmesi durumunda mühendislerimiz tarafından
                                            projelendirilip bahçe sulama
                            gibi durumlarda kullanılabilir.<br />
                                                Biyolojik Arıtma Sistemlerinin Kullanıldığı Alanlar;<br />
                                    <ul className="ulmargin">
                                        <li>Tatil Köyleri</li>
                                        <li>Turistik Tesisler</li>
                                        <li>Fabrika ve Sanayi Kuruluşları</li>
                                        <li>Yazlık Siteler ve Villalar</li>
                                        <li>Köy ve Belediyeler</li>
                                        <li>Sosyal Tesisler</li>
                                        <li>Okul, Hastane ve Askeri Birlikler vb.</li>
                                    </ul>
                                </h4>
                            </div>
                        </div>

                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/kimyasal.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="kimyasal"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Kimsayal Arıtma Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Kimyasal arıtmanın amacı, suda çözünmüş halde bulunan kirleticilerin kimyasal reaksiyonlarla
                                    çözünürlüğü düşük bileşiklere dönüştürülmesi ya da kolloidal ve askıdaki maddelerin yumaklar
                                    oluşturarak çökeltilmesi suretiyle giderilmesidir. Kimyasal arıtma içme suyu arıtımında ve
                            endüstriyel atık su arıtımında kullanılır.<br />
                                                        Kimyasal Arıtma Sistemlerinin Kullanıldığı Alanlar;<br />
                                    <ul className="ulmargin">
                                        <li>Tekstil Fabrikaları</li>
                                        <li>Mezbahaneler</li>
                                        <li>Hastaneler</li>
                                        <li>Galvaniz – Kaplama Endüstrisi</li>
                                        <li>Alüminyum Fabrikaları</li>
                                        <li>Boya Fabrikaları</li>
                                        <li>Yağ Fabrikaları</li>
                                        <li>Petrokimya Endüstrisi</li>
                                        <li>Et Entegre İşletmeleri vb.</li>
                                    </ul>
                                </h4>
                            </div>
                        </div>

                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/fiziksel.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="fiziksel"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Fiziksel Arıtma Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Atık su içerisindeki kirletici maddelerin fiziksel işlemlerle atık sudan alınması amacı ile
                                    kullanılan proseslerdir. Uygulamaları; ızgaralar, elekler, kum tutucular, yüzdürme
                                    sistemleri, çöktürme havuzları, dengeleme havuzlarıdır.
                        </h4>
                            </div>
                        </div>

                        <div class="uygulama-sistemleri">
                            <StaticImage
                                src="../images/uygulamalar/yagtutucu.jpg"
                                quality={100}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="yagtutucu"
                                className="uygulama-sistemleriimg"
                                placeholder={'blurred'}
                            />
                            <div class="uygulama-sistemleriyazi">
                                <h2 class="uygulama-sistemleri-h2">
                                    Yağ Tutucu Sistemleri
                        </h2>
                                <h4 class="uygulama-sistemleri-h4">
                                    Yağ tutucu sistemleri, atık suya karışan atık yağların fiziksel arıtım ile yağı sudan
                                    ayırarak kanalizasyona
                                    ulaşımını engeller.
                        </h4>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Contact />
        </Layout>
    )
}

export default Uygulamalar
